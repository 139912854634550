// -- basic library --
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { Stream, streamsIdGetAPI, streamsIdPutAPI } from 'admin/api/streams';
import { tenantsIdGetAPI } from 'admin/api/tenants';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { PageWholeArea, Title, Content, Breadcrumbs } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styled from 'styled-components';
import SelectBox from 'shared/components/atoms/SelectBox';
import { DataStorePeriodType, getDataStorePeriod, translateDataStorePeriodToJp } from 'shared/models/DataStorePeriod';
import { streaming_reception_billing_options } from 'shared/utils/constants';
import { secondsToDataStorePeriod } from 'shared/models/DataStorePeriod';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { isStreamingReception, isTimeStamp } from 'shared/utils/is';
// -- type declaration --
type Params = RouteComponentProps<{
  stream_id: string;
  tenant_id: string;
}>;

// -- main component --

const StreamDetailPage: React.FC<Params> = (params) => {
  // -- local states --;
  const [stream, setStream] = useState<Stream>();
  const [tenant_name, setTenantName] = useState<string>('');
  const [data_store_period, setDataStorePeriod] = useState<DataStorePeriodType>({
    data_store_period: -1,
    unit: 'UNSPECIFIED',
  });
  const [streaming_endpoint, setStreamingEndpoint] = useState<string | null>(null);
  const [edgebox_key, setEdgeboxKey] = useState<string | null>(null);
  const [is_published, setIsPublished] = useState<string>('');
  const handleCancel = () => {
    history.push('/streams');
  };
  const handleUpdate = async () => {
    if (!stream) return;
    if (data_store_period.data_store_period === 0) {
      AlertDialog.show('ストリームデータの保存期間を選択してください');
      return;
    }
    const seconds = getDataStorePeriod(data_store_period.unit, data_store_period.data_store_period);
    const res = await streamsIdPutAPI({
      stream_id: stream.stream_id,
      tenant_id: stream.tenant_id,
      data_store_period: seconds,
    });
    if (res.status === 200) {
      AlertDialog.show('ストリーム情報の更新に成功しました');
      history.push('/streams');
    }
  };

  // -- get external datas --
  useEffect(() => {
    streamsIdGetAPI({ stream_id: params.match.params.stream_id, tenant_id: params.match.params.tenant_id }).then(
      (res) => {
        if (res.status !== 200) return;
        const new_stream = res.data;
        setStream(new_stream);
        if (new_stream !== undefined) {
          tenantsIdGetAPI({ tenant_id: new_stream.tenant_id }).then((tenant) => {
            if (tenant.status === 200) {
              setTenantName(tenant.data.tenant_name);
            }
          });
          setDataStorePeriod(secondsToDataStorePeriod(new_stream.data_store_period));
          setStreamingEndpoint(new_stream.streaming_endpoint);
          setEdgeboxKey(new_stream.edgebox_key);
          setIsPublished(new_stream.is_published ? '〇' : '-');
        }
      },
    );
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea>
      <Breadcrumbs
        items={[
          { href: '/streams', icon: IconNames.PERSON, text: 'ストリーム' },
          { icon: IconNames.CUBE, text: stream?.stream_name },
        ]}
      />
      <Title text='ストリームの詳細' />
      <Content>
        <FormContainer title='ストリーム情報'>
          <InputComponent text='ストリームID：'>{stream?.stream_id}</InputComponent>
          <InputComponent text='ストリーム名：'>{stream?.stream_name}</InputComponent>
          <InputComponent text='テナントID：'>{stream?.tenant_id}</InputComponent>
          <InputComponent text='テナント名：'>{tenant_name}</InputComponent>
          <InputComponent text='チャンネルID：'>{stream?.channel_id}</InputComponent>
        </FormContainer>
        <FormContainer title='ストリーム詳細'>
          <InputComponent text='ストリームデータ番号の基準：'>{stream?.data_number_type}</InputComponent>
          <InputComponent text='ストリームデータの種類：'>{stream?.data_type}</InputComponent>
          <InputComponent text='ストリームデータの保存期間 (秒)：'>
            {isTimeStamp(stream?.data_number_type) ? (
              <SelectBox
                onChange={(e) => setDataStorePeriod({ data_store_period: Number(e.currentTarget.value), unit: 'DAY' })}
                value={String(data_store_period.data_store_period)}
                datas={streaming_reception_billing_options}
              />
            ) : (
              <div>{translateDataStorePeriodToJp(data_store_period)}</div>
            )}
          </InputComponent>
          <InputComponent text=' データの総件数：'>{stream?.total_data_count}</InputComponent>
          <InputComponent text=' データの総サイズ：'>{stream?.total_data_size}</InputComponent>
          <InputComponent text=' パッケージの総サイズ：'>{stream?.total_data_count}</InputComponent>
          <InputComponent text=' ストリーミング入力を行う場合のエンドポイントURL：'>
            {streaming_endpoint}
          </InputComponent>
          <InputComponent text=' 接続するエッジボックスのキー：'>{edgebox_key}</InputComponent>
          <InputComponent text=' このストリームを公開しているかどうか：'>{is_published}</InputComponent>
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} is_margin_right is_white text='戻る' />
          <RoundedButton onClick={handleUpdate} text='更新' disabled={!isTimeStamp(stream?.data_number_type)} />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};
export default StreamDetailPage;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
